import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";

type Props = {
  text: string;
  title: string;
};

export const B2BCard: React.FC<Props> = ({ text, title }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Title>{t(title)}</Title>
      <Info>{t(text)}</Info>
    </Card>
  );
};

const Card = styled("div", {
  background: "$primaryBackground",
  borderRadius: "8px",
  padding: 24,
  height: "fix-content",
  minHeight: "222px",
  "@xs": {
    width: "700px",
    height: "240px",
  },
  "@md": {
    width: "600px",
    height: "264px",
    padding: 40,
  },
});

const Title = styled("h1", {
  color: "$text",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  textAlign: "center",
  margin: '0 0 18px',
  '@md': {
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "start",
    margin: '0 0 12px',
  }
});

const Info = styled("p", {
  color: "$text",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "20px",
  textAlign: "start",
  margin: 0,
  '@md': {
    fontSize: "17px",
    lineHeight: "24px",
  }
});
