import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import MainImage from "../../images/b2b/b2b-image.svg";
import { B2BCard } from "../../components/B2B/B2BCard";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Button } from "../../components/shared/Button";
import { Seo } from "../../components/shared/Seo";

type b2bCard = {
  title: string;
  text: string;
};

const b2bCards: b2bCard[] = [
  {
    title: "b2b.card1.title",
    text: "b2b.card1.text",
  },
  {
    title: "b2b.card2.title",
    text: "b2b.card2.text",
  },
];

function B2BProcurement() {
  const { t } = useTranslation();
  const bgColor = Colors.transparent;
  const { navigate } = useI18next();

  return (
    <Layout>
      <Seo
        title={t("b2b.title")}
        description={t("b2b.mainText1")}
        image={MainImage}
      />
      <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
        <BreadBox>
          <BreadCrumbs
            root={{ link: routes.tbizedi.edi, title: "tbizedi" }}
            current="tbizediSubs.procurement"
          />
        </BreadBox>
        <Heading color={bgColor}>{t("b2b.title")}</Heading>
        <HeroDesc color={bgColor}>{t("b2b.mainText1")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("b2b.mainText2")}</HeroDesc>
        <ButtonsWrap>
          <Button onClick={() => navigate(routes.contact.contactForm)}>
            {t("eDataGatewayContactUs")}
          </Button>
        </ButtonsWrap>
      </Hero>
      <FunctionsSection>
        <PageTitle>{t("b2b.sub.title")}</PageTitle>
        <Box>
          {b2bCards.map((el, i) => (
            <B2BCard key={i} title={el.title} text={el.text} />
          ))}
        </Box>
      </FunctionsSection>
    </Layout>
  );
}

export default B2BProcurement;

const FunctionsSection = styled("section", {
  backgroundColor: "$white",
  textAlign: "center",
  margin: "60px auto",
  maxWidth: "1280px",
});

const Box = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: 32,
  padding: "0 16px",
  margin: "40px auto 20px",
  "@md": {
    marginBottom: 0,
    padding: 0,
    gap: 80,
    flexDirection: "row",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginBottom: 80,
  "@md": {
    flexDirection: "row",
    marginBottom: "100px",
  },
});

const BreadBox = styled("div", {});

const Image = styled("img", {
  objectFit: "contain",
  maxWidth: "464px",
  width: "90%",
});

const PageTitle = styled("h1", {
  color: "$heading",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",
  letterSpacing: "-0.022em",
  margin: 0,
  "@md": {
    fontSize: "24px",
    lineHeight: "150%",
  },
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",
  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0px",
  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "&:last-of-type": {
    margin: "0 0 32px",
  },
  "@md": {
    color: "$text",
  },
});
