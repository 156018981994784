import * as React from "react";
import { graphql } from "gatsby";
import B2BProcurement from "../../views/B2BProcurement/B2BProcurement";

const B2BProcurementPage = () => {
  return <B2BProcurement />;
};

export default B2BProcurementPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
